<script lang="ts">
  import type { FAQ } from '$lib/contentful/models/faq';
  import Text from '$lib/components/text/text.svelte';
  import RichText from '../rich-text/rich-text.svelte';

  export let content: FAQ;
  $: ({ title, entityId, questionsAndAnswers } = content);
</script>

<div class="px-8 md:px-12 lg:px-24 xl:px-32 py-12 flex flex-col gap-8 flex-1">
  <Text {entityId} fieldId="title" variant="display-68">{title}</Text>
  {#each questionsAndAnswers as { question, answer, entityId }}
    <Text fieldId="question" {entityId} variant="body-32">{question}</Text>
    <RichText fieldId="answer" {entityId} text={answer} />
  {/each}
</div>
