<script lang="ts">
  import Grid from '$lib/layout/grid/grid.svelte';
  import PageHero from '$lib/components/page-hero/page-hero.svelte';
  import ContentBlock from '$lib/components/content-block/content-block.svelte';
  import PromoBlock from '$lib/components/promo-block/promo-block.svelte';
  import Tabs from '$lib/components/tabs/tabs.svelte';
  import CloudHero from '$lib/components/cloud-hero/cloud-hero.svelte';
  import CardHero from '$lib/components/card-hero/card-hero.svelte';
  import SectionHeading from '$lib/components/section-heading/section-heading.svelte';
  import SectionHero from '$lib/components/section-hero/section-hero.svelte';
  import LogoGroup from '$lib/components/logo-group/logo-group.svelte';
  import MarketoForm from '$lib/components/marketo-form/marketo-form.svelte';
  import type { Body, BodyContentType } from '$lib/contentful/models/web-page';
  import type { PageHero as IPageHero } from '$lib/contentful/models/page-hero';
  import BrowserBlock from '$lib/components/browser-block/browser-block.svelte';
  import OrderedContent from '$lib/components/ordered-content/ordered-content.svelte';
  import Faq from '$lib/components/faq/faq.svelte';
  import Seo from '$lib/components/seo/seo.svelte';
  import FilterableResources from '$lib/components/filterable-resources/filterable-resources.svelte';

  export let title: string;
  export let description: string;
  export let path: string;
  export let keywords: string | undefined = undefined;
  export let image: string | undefined = undefined;
  export let hero: IPageHero | undefined = undefined;
  export let body: Body;
  export let nofollow: boolean = false;
  export let noindex: boolean = false;

  const ComponentMap: Record<
    BodyContentType,
    ConstructorOfATypedSvelteComponent
  > = {
    browserBlock: BrowserBlock,
    contentBlock: ContentBlock,
    noise: PromoBlock,
    tabGroup: Tabs,
    cloudHero: CloudHero,
    cardHero: CardHero,
    marketoForm: MarketoForm,
    sectionHeading: SectionHeading,
    sectionHero: SectionHero,
    logoGroup: LogoGroup,
    orderedContent: OrderedContent,
    faq: Faq,
    filterableResources: FilterableResources,
  };
</script>

<Seo seo={{ title, description, keywords, image, path, nofollow, noindex }} />
<main>
  {#if hero}
    <PageHero content={hero} />
  {/if}
  <Grid as="div" layout="rows">
    <slot name="before" />
    {#each body as content}
      <svelte:component this={ComponentMap[content.contentType]} {content} />
    {/each}
    <slot />
  </Grid>
</main>
