<script lang="ts">
  import type { ContentBlock } from '$lib/contentful/models/content-block';
  import Text from '$lib/components/text/text.svelte';
  import Button from '$lib/components/button/button.svelte';
  import RichText from '../rich-text/rich-text.svelte';
  import OrderedIndex from './ordered-index.svelte';
  import Box from '$lib/layout/grid/box.svelte';
  import Grid from '$lib/layout/grid/grid.svelte';
  import Icon from '../icon/icon.svelte';
  import ContentfulImage from '../contentful-image/contentful-image.svelte';

  type $$Props = {
    content: ContentBlock | undefined;
    id?: string;
    index: number;
    direction?: 'row' | 'col' | 'col-reverse';
    diveDeeper?: boolean;
    lastItem?: boolean;
    leftSpan?: number;
  };

  export let content: ContentBlock | undefined;
  export let id = '';
  export let index = 0;
  export let direction: 'row' | 'col' | 'col-reverse' = 'row';
  export let diveDeeper = false;
  export let lastItem = false;
  export let leftSpan = 5;
</script>

{#if content}
  {@const { heading, body, image, callsToAction, entityId } = content}
  <Grid
    {id}
    layout="columns"
    as="section"
    class="max-lg:hidden bg-stars bg-contain"
  >
    <Box as="div" offset={2} span={1}>
      <OrderedIndex {direction} {index} {lastItem} />
    </Box>
    {#if direction === 'row'}
      <Box as="div" offset={3} span={leftSpan}>
        <Text
          as="h3"
          variant="heading-48"
          class="text-off-white"
          {entityId}
          fieldId="heading"
        >
          {heading}
        </Text>
        <RichText class="w-4/5" fieldId="body" {entityId} text={body} />
        {#if !diveDeeper && callsToAction?.length}
          <div class="flex flex-row gap-8 mt-4">
            {#each callsToAction as { text, ...rest }}
              <Button {...rest}>{text}</Button>
            {/each}
          </div>
        {/if}
      </Box>
      <Box
        as="div"
        offset={4 + leftSpan}
        span={9 - leftSpan}
        class="flex flex-col gap-16"
      >
        {#if diveDeeper && callsToAction?.length}
          <div class="dive-deeper">
            <Text as="p" variant="eyebrow-16" class="text-gradient-green"
              >Dive Deeper</Text
            >
            <div class="flex flex-row gap-4">
              {#each callsToAction as { text, href }}
                <a {href} class="dive-deeper-link">
                  {text}
                  <Icon name="arrow-right" class="text-mint" />
                </a>
              {/each}
            </div>
          </div>
        {/if}
        {#if lastItem}
          <img
            src="/images/monitor-background-graphic.png"
            alt="graphic"
            class="absolute lg:-translate-x-[10rem] lg:-translate-y-40 max-lg:w-1/2 max-lg:translate-x-1/2 z-0"
          />
        {/if}
        <ContentfulImage
          src={image?.url}
          alt={image?.title}
          data-contentful-entry-id={entityId}
          data-contentful-field-id="image"
          pictureClass="z-10"
          class={`rounded-xl w-full ${lastItem ? 'border-8' : ''}`}
        />
      </Box>
    {:else if direction === 'col'}
      <Box as="div" span={leftSpan}>
        <Text as="h3" variant="heading-48" class="text-off-white" {entityId}>
          {heading}
        </Text>
        <RichText class="w-4/5" fieldId="body" {entityId} text={body} />
        {#if !diveDeeper && callsToAction?.length}
          <div class="flex flex-row gap-8 mt-4">
            {#each callsToAction as { text, ...rest }}
              <Button {...rest}>{text}</Button>
            {/each}
          </div>
        {/if}
      </Box>
      {#if diveDeeper && callsToAction?.length}
        <Box
          as="div"
          offset={leftSpan + 4}
          span={9 - leftSpan}
          class="flex flex-col"
        >
          <div class="dive-deeper">
            <Text as="p" variant="eyebrow-16" class="text-gradient-green"
              >Dive Deeper</Text
            >
            <div class="flex flex-row gap-4">
              {#each callsToAction as { text, href }}
                <a {href} class="dive-deeper-link">
                  {text}
                  <Icon name="arrow-right" class="text-mint" />
                </a>
              {/each}
            </div>
          </div>
        </Box>
      {/if}
      <Box as="div" span={8} offset={4}>
        <ContentfulImage
          src={image?.url}
          alt={image?.title}
          data-contentful-entry-id={entityId}
          data-contentful-field-id={'image'}
        />
      </Box>
    {:else}
      <Box as="div">
        <Text as="h3" variant="heading-48" class="text-off-white" {entityId}>
          {heading}
        </Text>
        <ContentfulImage
          class="py-12 px-24"
          src={image?.url}
          alt={image?.title}
          data-contentful-entry-id={entityId}
          data-contentful-field-id={'image'}
        />
      </Box>
      <Box as="div">
        <RichText
          class="flex lg:flex-row lg:gap-24 text-left max-lg:flex-col max-lg:gap-4"
          fieldId="body"
          {entityId}
          text={body}
        />
      </Box>
    {/if}
  </Grid>
  <div class="lg:hidden flex flex-col px-10 bg-stars bg-contain">
    <Text
      as="h3"
      variant="heading-48"
      class="text-off-white flex items-center gap-2"
      {entityId}
    >
      <OrderedIndex {direction} {index} {lastItem} />
      {heading}
    </Text>
    <RichText
      class="flex lg:flex-row lg:gap-24 text-left max-lg:flex-col max-lg:gap-4"
      fieldId="body"
      {entityId}
      text={body}
    />
    <ContentfulImage
      src={image?.url}
      alt={image?.title}
      data-contentful-entry-id={entityId}
      data-contentful-field-id={'image'}
      class={`m-12 z-10 rounded-xl ${lastItem ? 'border-8' : ''}`}
    />
  </div>
{/if}

<style lang="postcss">
  .dive-deeper {
    @apply self-end bg-graphite max-w-fit rounded p-8 flex flex-col gap-2 w-full;
  }

  .dive-deeper-link {
    @apply flex flex-row gap-4 font-mono text-base text-white;
  }
</style>
