<script lang="ts">
  import type { SectionHero } from '$lib/contentful/models/section-hero';
  import Text from '$lib/components/text/text.svelte';
  import Box from '../../layout/grid/box.svelte';
  import Grid from '../../layout/grid/grid.svelte';
  import Button from '$lib/components/button/button.svelte';
  import ContentfulImage from '../contentful-image/contentful-image.svelte';

  export let content: SectionHero;
  $: ({ eyebrow, heading, body, image, callsToAction, anchor, background } =
    content);
</script>

<Grid
  as="section"
  layout="columns"
  class="max-sm:gap-24"
  {background}
  id={anchor}
>
  <Box as="div" offset={3} span={image ? 6 : 10} class="max-sm:order-last">
    <div class="content">
      {#if eyebrow}
        <Text class="text-gradient-mist" as="h2" variant="eyebrow-16">
          {eyebrow}
        </Text>
      {/if}
      <Text as="h3" variant="heading-68">
        {heading}
      </Text>
      {#if body}
        <Text as="p" variant="body-24" class="text-indigo-100">
          {body}
        </Text>
      {/if}
      {#each callsToAction as { text, ...rest }}
        <Button target="_blank" {...rest}>{text}</Button>
      {/each}
    </div>
  </Box>
  <Box as="div" offset={9} span={5} class="my-auto mx-auto">
    {#if image}
      <ContentfulImage
        class="max-h-[600px] rounded-lg"
        src={image.url}
        alt={image.title}
      />
    {/if}
  </Box>
</Grid>

<style lang="postcss">
  .content {
    @apply flex flex-col gap-2 max-sm:px-5;
  }
</style>
